
import { Vue, Component } from "vue-property-decorator"
import { FetchResult } from "apollo-link"
import AppDetailPage from "@/components/layout/AppDetailPage.vue"
import { UsersQuery, UsersQueryQuery, RolesFilter, UserAdvancedFragmentFragment } from "@/gql"
import AddUserForm from "@/components/forms/AddUserForm.vue"
import { ActionType } from "@/components/widgets/common/FilterBar.vue"
import Loader from "@/components/widgets/common/Loader.vue"

@Component({ components: { AddUserForm, AppDetailPage, Loader } })
export default class UserRoles extends Vue {
  itemActions!: ActionType
  readonly query = UsersQuery

  showAddUserDialog = false
  formLabel = "Add new user"

  filter: RolesFilter = {}
  activeUser: UserAdvancedFragmentFragment | null = null
  user: UserAdvancedFragmentFragment | null = null

  editUser() {
    this.formLabel = "Edit user"
    this.activeUser = this.user
    this.showAddUserDialog = true
  }

  onResult(result: FetchResult<UsersQueryQuery>) {
    if (result.data) {
      this.user = result.data.users.data[0] as unknown as UserAdvancedFragmentFragment
    }
  }

  mounted() {
    this.filter.idIn = [this.$route.params.id]
  }
}
