
import { Vue, Component } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import { UserRolesQuery, Role } from "@/gql"
import AddUserRoleForm from "@/components/forms/AddUserRoleForm.vue"
import { ActionType } from "@/components/widgets/common/FilterBar.vue"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"

@Component({ components: { AppPage, AddUserRoleForm } })
export default class UserRoles extends Vue {
  readonly userRolesQuery = UserRolesQuery
  itemActions!: ActionType
  showAddUserRoleDialog = false
  activeRole: Role | null = null
  formLabel = "Add new role"

  refetchQueries: RefetchQueryDescription = [
    {
      query: UserRolesQuery,
      variables: {
        filter: {},
        page: 1,
        per: 10,
      },
    },
  ]

  readonly headers = [
    { text: "Name", value: "name" },
    { text: "Description", value: "description" },
    { text: "Status", value: "active", custom: true },
  ]

  gotoDetailPage(role: Role) {
    this.$router.push({
      name: this.$routes.UserRoleDetail,
      params: { id: role.id.toString() },
    })
  }

  addNewRole() {
    this.formLabel = "Add new role"
    this.activeRole = null
    this.showAddUserRoleDialog = true
  }

  created() {
    this.itemActions = {
      View: (role: Role) => {
        this.gotoDetailPage(role)
      },
      Edit: (role: Role) => {
        this.activeRole = role
        this.formLabel = "Edit role"
        this.showAddUserRoleDialog = true
      },
    }
  }
}
