
import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import { PermissionsQuery, Role, AddUserRoleMutation, UpdateUserRoleMutation } from "@/gql"

@Component
export default class AddUserRoleForm extends Vue {
  @Prop()
  readonly role?: Role
  readonly PermissionsQuery = PermissionsQuery
  @Prop() readonly refetchQueries!: any

  loading = false
  defaultForm: {
    id: number
    name: string
    description: string
    permissionSlugs: string[]
    active: boolean
  } = {
    id: 0,
    name: "",
    description: "",
    permissionSlugs: [],
    active: false,
  }

  form = { ...this.defaultForm }

  @Watch("role", { immediate: true })
  onRoleChange() {
    if (this.role) {
      this.form.id = this.role.id
      this.form.name = this.role.name
      this.form.description = this.role.description
      this.form.active = this.role.active
      this.form.permissionSlugs = this.role.permissions.map((x) => x.slug)
    } else {
      this.form = {
        ...this.defaultForm,
      }
    }
  }

  async addUserRole() {
    try {
      this.loading = true
      let mutation = this.role ? UpdateUserRoleMutation : AddUserRoleMutation

      const result = await this.$apollo.mutate({
        mutation: mutation,
        variables: this.form,
        refetchQueries: this.refetchQueries,
      })

      if (result && result.data) {
        let data = this.role ? result.data.updateRole : result.data.addRole,
          successMsg = this.role ? "Role updated successfully" : "Role created successfully"

        if (data.error) this.addMutationError(data.error)
        else {
          this.addSuccess(successMsg)
          this.$emit("close")

          this.form = {
            ...this.defaultForm,
          }
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    } finally {
      this.loading = false
    }
  }
}
