
import { Vue, Component } from "vue-property-decorator"
import { FetchResult } from "apollo-link"
import AppDetailPage from "@/components/layout/AppDetailPage.vue"
import { UserRolesQuery, UserRolesQueryQuery, RoleFragmentFragment, RolesFilter } from "@/gql"
import AddUserRoleForm from "@/components/forms/AddUserRoleForm.vue"
import { ActionType } from "@/components/widgets/common/FilterBar.vue"
import Loader from "@/components/widgets/common/Loader.vue"

@Component({ components: { AppDetailPage, AddUserRoleForm, Loader } })
export default class UserRoleDetail extends Vue {
  itemActions!: ActionType
  readonly query = UserRolesQuery

  activeRole: RoleFragmentFragment | null = null
  role: RoleFragmentFragment | null = null
  filter: RolesFilter = {}

  formLabel = "Add new role"
  showAddUserRoleDialog = false

  editRole() {
    this.formLabel = "Edit Role"
    this.activeRole = this.role
    this.showAddUserRoleDialog = true
  }

  onResult(result: FetchResult<UserRolesQueryQuery>) {
    if (result.data) {
      this.role = result.data.roles.data[0]
    }
  }

  mounted() {
    this.filter.idIn = [this.$route.params.id]
  }

  refetchQueries() {
    return [
      {
        query: this.query,
        variables: {
          filter: this.filter,
          per: 1,
        },
      },
    ]
  }
}
