var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppDetailPage',{attrs:{"title":"Role"}},[_c('v-card',{staticClass:"pa-5"},[_c('ApolloQuery',{attrs:{"query":_vm.query,"variables":{
        filter: _vm.filter,
        per: 1,
      },"notifyOnNetworkStatusChange":""},on:{"error":_vm.addGraphQLError,"result":_vm.onResult},scopedSlots:_vm._u([{key:"default",fn:function({ result: { loading } }){return [(loading)?_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_c('Loader')],1)]):_vm._e(),_c('div',{staticClass:"tools d-flex"},[_c('v-spacer'),_c('SecondaryButton',{on:{"click":_vm.editRole}},[_vm._v("Edit")]),_c('form-dialog',{attrs:{"label":_vm.formLabel},model:{value:(_vm.showAddUserRoleDialog),callback:function ($$v) {_vm.showAddUserRoleDialog=$$v},expression:"showAddUserRoleDialog"}},[_c('add-user-role-form',{attrs:{"role":_vm.activeRole,"refetchQueries":_vm.refetchQueries},on:{"close":() => {
                  ;(_vm.showAddUserRoleDialog = false), (_vm.activeRole = null)
                }}})],1)],1),(_vm.role)?[_c('div',{staticClass:"user-profile-card-main ma-2 text-center"},[_c('div',[_c('div',{staticClass:"user-name font-weight-bold headline"},[_vm._v(" "+_vm._s(_vm.role.name)+" ")]),[_c('div',{staticClass:"mbti-jobtitle"},[_c('span',{domProps:{"textContent":_vm._s(_vm.role.description)}})]),_c('div',[(_vm.role)?_c('v-chip',{staticClass:"px-5 mx-6",attrs:{"color":_vm.role.active
                        ? 'green lighten-5 green--text'
                        : 'amber lighten-5 amber--text text--darken-2',"small":""}},[_vm._v(_vm._s(_vm.role.active ? "Active" : "Inactive"))]):_vm._e()],1)]],2)]),_c('v-divider',{staticClass:"mt-9"}),_c('div',{staticClass:"d-flex"},[_c('header2',[_vm._v("Permissions")])],1),_c('div',{staticClass:"card-content"},[_c('div',{},_vm._l((_vm.role.permissions),function(permission){return _c('v-chip',{key:permission.id,staticClass:"ma-2"},[_vm._v(" "+_vm._s(permission.name)+" ")])}),1)])]:_vm._e()]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }