var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppDetailPage',{attrs:{"title":"User"}},[_c('v-card',{staticClass:"pa-5"},[_c('ApolloQuery',{attrs:{"query":_vm.query,"variables":{
        filter: _vm.filter,
        per: 1,
      },"notifyOnNetworkStatusChange":""},on:{"error":_vm.addGraphQLError,"result":_vm.onResult},scopedSlots:_vm._u([{key:"default",fn:function({ result: { loading } }){return [(loading)?_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_c('Loader')],1)]):_vm._e(),_c('div',{staticClass:"tools d-flex"},[_c('v-spacer'),_c('SecondaryButton',{on:{"click":_vm.editUser}},[_vm._v("Edit")]),_c('form-dialog',{attrs:{"label":_vm.formLabel},model:{value:(_vm.showAddUserDialog),callback:function ($$v) {_vm.showAddUserDialog=$$v},expression:"showAddUserDialog"}},[_c('add-user-form',{attrs:{"user":_vm.activeUser,"queries":['usersQuery']},on:{"close":() => {
                  _vm.showAddUserDialog = false
                  _vm.activeUser = null
                }}})],1)],1),(_vm.user)?[_c('div',{staticClass:"user-profile-card-main ma-2 text-center"},[_c('v-avatar',{staticClass:"ma-4",attrs:{"size":"100","color":"grey lighten-2"}},[(_vm.user && _vm.user.avatar)?_c('v-img',{staticClass:"text-center",attrs:{"src":_vm.user.avatar.url}}):_c('h1',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.user.firstName)}})],1),_c('div',[_c('div',{staticClass:"user-name font-weight-bold headline"},[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")]),[_c('div',{staticClass:"mbti-jobtitle"},[_c('span',{domProps:{"textContent":_vm._s(_vm.user.email)}})])]],2)],1),_c('v-divider',{staticClass:"mt-9"}),_c('div',{staticClass:"d-flex"},[_c('header2',[_vm._v("Roles")])],1),_c('div',{staticClass:"card-content"},[_c('div',{},_vm._l((_vm.user.roles),function(role){return _c('v-chip',{key:role.id,staticClass:"ma-2"},[_vm._v(" "+_vm._s(role.name)+" ")])}),1)])]:_vm._e()]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }